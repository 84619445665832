.App {
  background-color: #1a1a1a;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
}

.App-header {
  background-color: #1a1a1a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  margin: 0px;
}

p {
  padding: 0% 15%;
  font-size: calc(5px + 2vmin);
  max-width: 700px;
}

.Download-Links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  text-align: center;
  width: 100%;
}

.Download-Button {
  margin: 0% 2%;
  max-width: 190px;
  width: 20%;
  height: auto;
  min-width: 120px;
}